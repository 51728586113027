@font-face {
  font-family: 'Brown Regular';
  src: url('../assets/fonts/Brown-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'Brown Bold';
  src: url('../assets/fonts/Brown-Bold.ttf') format('opentype');
}

@font-face {
  font-family: 'Brown Light';
  src: url('../assets/fonts/Brown-Light.ttf') format('opentype');
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Brown Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.col {
  top: 0;
  bottom: 0;
}
